<template>
  <div class="fixed bottom-6 right-5 left-5 z-alert select-none pointer-events-none md:w-80 md:top-22 md:right-16 md:left-auto md:bottom-auto">
    <nd-alert v-bind="alertProps" class="transition-opacity z-alert pr-5" :style="alertStyle">
      {{ alertMessage }}
    </nd-alert>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import NdAlert from '@/components/common/nd-alert.vue'

import { useAlertStore } from '@/store/alert'

const { isAlertShown, icon, alertMessage, alertType } = storeToRefs(useAlertStore())

const alertProps = computed(() => {
  return {
    type: alertType.value,
    icon: icon.value || undefined,
  }
})

const alertStyle = computed(() => ({
  opacity: isAlertShown.value ? 1 : 0,
}))
</script>

<style lang="scss" scoped></style>
